<template>
  <div class="content_container">

    <el-breadcrumb separator-class="el-icon-arrow-right " class="titleNav">

      <el-breadcrumb-item>
        <span @click="$router.push('/')">首页 </span>
      </el-breadcrumb-item>
      <el-breadcrumb-item>有害信息反馈</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="cont">

      <dl>
        <dt> 有害信息类型：</dt>
        <dd>
          <el-select v-model="type" filterable placeholder="请选择">
            <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </dd>
      </dl>

      <dl>
        <dt>有害信息描述：</dt>
        <dd>
          <el-input type="textarea" placeholder="请输入内容" v-model="content" maxlength="300" show-word-limit>
          </el-input>
        </dd>
      </dl>
      <dl>
        <dt>涉及网址：</dt>
        <dd>
          <el-input v-model='url' placeholder="请输入内容">
          </el-input>
        </dd>
      </dl>
      <dl>
        <dt></dt>
        <dd>
          <uploader field="img" v-model="gallery" exts="png||jpg||gif" value-type="urlStr" />

        </dd>
      </dl>
      <!-- <div>
        <p> 您对仪商城的整体满意度是：
        </p>
        <el-radio-group v-model="star">
          <el-radio
            :label="0"
            style="margin-top:20px;"
          >非常满意</el-radio><br />
          <el-radio
            :label="1"
            style="margin-top:20px;"
          >满意</el-radio><br />
          <el-radio
            :label="2"
            style="margin-top:20px;"
          >一般</el-radio><br />
          <el-radio
            :label="3"
            style="margin-top:20px;"
          >不满意</el-radio><br />
          <el-radio
            :label="4"
            style="margin-top:20px;"
          >非常不满意</el-radio><br />
        </el-radio-group>
      </div> -->
      <p style="text-align:center">
        <el-button type="primary" @click="summit">提 交</el-button>
      </p>
    </div>

  </div>

</template>

<script type="text/javascript">
export default {
  data() {
    return {
      type: 0,
      star: 0,
      content: "",
      email: "",
      url: '',
      gallery: [],
      baseUrl: this.$config.baseUrl,
      options: [
        {
          name: "封建迷信",
          id: 0
        },
        {
          name: "黄赌毒",
          id: 1
        },
        {
          name: "黑客木马病毒",
          id: 2
        },
        {
          name: "侮辱诽谤他人",
          id: 3
        },
        {
          name: "散布谣言扰乱秩序",
          id: 4
        },
        {
          name: "血腥凶杀",
          id: 5
        },
        {
          name: "违法或教唆犯罪",
          id: 6
        },
        {
          name: "危害国家破坏团结",
          id: 7
        },
        {
          name: "其他有害信息",
          id: 8
        }
      ]
    };
  },
  filters: {},
  methods: {
    summit() {
      let data = {
        type: 1,
        name: "",
        email: this.email,
        content: this.content,
        con_type: "",
        gallery: this.gallery.toString(),
        url: "",
        star: 1,
        member_id: this.$store.state.member.id,
        member_name: this.$store.state.member.name
      };

      if (!this.content) {
        this.$message.warning("请填写意见内容");
        return;
      }

      this.$post("home/postmsg", data).then(() => {
        this.$message.success("提交成功");
        this.$router.push("/");
      });
    }
  },
  components: {},
  created() { }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins";

dl {
  width: 100%;
  margin-bottom: 20px;
  @include space-between;
  dt {
    width: 100px;
  }
  dd {
    flex: 1;
  }
}
.cont {
  padding: 20px 100px;
  background: #fff;
  line-height: 30px;
  box-sizing: border-box;
  min-height: 600px;
}
::v-deep .el-form-item {
  margin-bottom: 2px;
}
.content_container {
  min-height: auto !important;
}

.titleNav {
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  background: #fff;
}
</style>
